import React from "react";
import { SideNavigation } from "../Sidebar/Navigation";
import "chart.js/auto";
import "../Profile/Profile.css";
import "../../App.css";

export const About = () => {
	return (
		<div className="dashboard d-flex">
			<div>
				<SideNavigation></SideNavigation>
			</div>
			<div
				style={{
					flex: "1 1 auto",
					display: "flex",
					flexFlow: "column",
					height: "100vh",
					overflowY: "hidden",
				}}
			>
				<div className="p-5">
					<p className="h4 fw-bolder">In-SWALST</p>
					<p className="text-muted">v2.10.2024</p>
				</div>
			</div>
		</div>
	);
};
